import React from 'react';

const IconLogo = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 800 800"
    style={{ enableBackground: 'new 0 0 800 800' }}
    xmlSpace="preserve">
    <style type="text/css">{`.st0 { fill: #FFFFFF; }`}</style>
    <g>
      <path
        className="st0"
        d="M620.7,551.9c-23.3,33.7-54.1,61.9-90,82L401.1,350.2L269.8,634.2l-9.1,19.7l-33.8,73l-9.1,19.7l-0.8,1.7H89.3 l33.2-72.2l9.7-21.1l36.9-80.3l10.4-22.6l158.6-345.5h124.5L620.7,551.9z"
      />
      <path
        className="st0"
        d="M400,30C196,30,30,196,30,400c0,90.3,32.5,173.1,86.4,237.4l38.3-83.4c-28.1-44.6-44.4-97.5-44.4-154 c0-159.7,130-289.7,289.7-289.7c159.7,0,289.7,130,289.7,289.7c0,56.4-16.2,109.2-44.3,153.8c-4.5,7.1-9.3,14.1-14.4,20.8 c-24.5,32.3-55.6,59.4-91.3,79.1c-6.3,3.5-12.8,6.8-19.4,9.8c-36.7,16.8-77.5,26.2-120.4,26.2c-42.8,0-83.4-9.3-120-26l-33.7,72.8 C293.1,758,345.2,770,400,770c54.8,0,106.9-12,153.8-33.5c6.6-3,13.1-6.2,19.4-9.6c35.4-18.8,67.3-43.2,94.7-71.9 c5.4-5.7,10.7-11.6,15.8-17.7C737.5,573,770,490.2,770,400C770,196,604,30,400,30z"
      />
      <path
        className="st0"
        d="M677.6,676.1c-27.9,28.1-60,51.9-95.3,70.5l0.8,1.7h127.6L677.6,676.1z"
      />
      <line className="st0" x1="272" y1="578.8" x2="272" y2="578.9" />
    </g>
  </svg>
);

export default IconLogo;
